
.first-container {
    position: absolute;
    top: 0;
    background-color: transparent;
    font-family: 'Titillium Web', sans-serif;
    height: 100svh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    text-align: center;
    object-fit: contain;
    padding: 9rem 2rem 4rem;
    margin-left: auto;
    margin-right: auto;
}
.container-background {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: -1;
}
.container-background img{
    object-fit: cover;
}
.logo-container{
    border: solid 1px #ffffff;
    width: 10vw;
    border-radius: 1rem;
    margin-bottom: 5vh;
}

.logo-container::after{
    content: '';
    display: block;
    position: relative;
    left: calc(50% - 1px);
    width: 1px;
    height: 5vh;
    background: #ffffff;
}

.first-container-effect{
    margin-top: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 1;
}
.first-container-title{
    background-color: var(--transparentBlackColor);
    margin-top: -30vh;
    display: flex;
    max-width: 70vw;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    color: var(--textColor);
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    padding: 3rem 2rem;
}

.first-container-title-inner{
    max-height: 15vh;
    align-items: center;
    justify-content: center;
}
.first-container-title-inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}
.first-container-title h1{
    font-size: 2rem;
    font-weight: 600;
}

.first-container-title p{
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.8rem;
    line-height: 2;
}
.first-container-effect nav{
    width: 70vw;
}
.first-container nav::before{
    content: '';
    display: block;
    position: relative;
    left: calc(50%);
    width: 1px;
    height: 5vh;
    background: #ffffff;
}

.first-container nav ul{
    display: flex;
    flex-direction: row;
	margin-bottom: 0;
	list-style: none;
	padding-left: 0;
	border: solid 1px #ffffff;
	border-radius: 4px;
}

.first-container nav ul li {
    background-color: var(--transparentBlackColor);
    width: 100%;
    padding-left: 0;
    border-left: solid 1px #ffffff;
}

.first-container nav ul li:first-child {
        border-left: 0;
    }

    .first-container nav ul li a {
        display: block;
        min-width: 10rem;
        line-height: 2.75rem;
        text-transform: uppercase;
        list-style: none;
        letter-spacing: 0.2rem;
        font-size: 0.9rem;
        font-weight: 600;
        border-bottom: 0;
        list-style: none;
    }

    .first-container nav ul li:hover {
            background-color: rgba(255, 255, 255, 0.6);
        }

        .first-container nav ul li a:hover {
            color: rgba(0,0,0,1);
            
        }

        .first-container nav ul li a:active {
            background-color: rgba(255, 255, 255, 0.175);
        }

@media only screen and (max-width: 1150px) {

    .first-container {
        object-fit: cover ;
        padding: 4rem 1rem;
    }

    .logo-container{
        max-height: 20vh;
        max-width: 50vw;
        line-height: 3.5rem;
        border: solid 1px #ffffff;
        border-radius: 1rem;
        justify-content: center;
        align-items: center;
        margin-bottom: 5vh;
    }

    .first-container-title{
        padding: 1.5rem 0.8rem;
        margin-top: -3vh;
        margin-bottom: 0;
    }
    .first-container-title h1{
        font-weight: 600;
    }
    

    .first-container nav ul{
        display: flex;
        flex-direction: column;
        border: none;
    }

    .first-container nav ul li::after{
        content: '';
		display: block;
		position: relative;
        top: 6px;
		left: calc(50% - 1px);
		width: 1px;
		height: 6px;
		background: #ffffff;
    }
    .first-container-effect {
        margin-top: 25vh;
    }
    .first-container nav ul li:last-child:after{
        display: none;
    }
    
    .first-container nav ul li {
        border: solid 1px #ffffff;
        border-radius: 4px;
        margin-bottom: 5px;

    }
    .first-container nav ul li a{
        font-size: 0.9rem;
    }

    .first-container nav ul li:first-child {
        border-left: solid 1px #ffffff;
    }

    .first-container-title-inner{
        max-height: 10vh;
    }
}

.popup-container{
    position: absolute;
    top: 0;
    display: flex;
    z-index:-1;
}
.popup-container.active{
    position: absolute;
    top: 0;
    display: flex;
    z-index:5;
    background-color: black;
}

.popup-container article{
    opacity: 0;   
    height: 100vh;
    transform: translateY(0);
    background-color: black;
    display: block;
    z-index: -1;
}
.popup-container article.active{
    opacity: 1;
    z-index: 1000;
    background-color: yellow;
    align-items: center;
    justify-content: center;

    .popup-container{
        position: absolute;
        top: 0;
        display: flex;
        z-index:1;
    }
}

.major {
    color: black;
}