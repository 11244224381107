.group-wrapper{
    margin: 4rem 6rem;
}
.buttons-container {
    position: absolute;
    top: 30.6vh;
    width: 100%;
}
.buttons-container nav{
    width: 70vw;
    background-color: var(--transparentBlackColor);
    padding: 0rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}


.buttons-container nav ul{
    display: flex;
    flex-direction: row;
	margin-bottom: 0;
	list-style: none;
	padding-left: 0;
}

.buttons-container nav ul li {
    width: 100%;
    padding-left: 0;
}

.buttons-container nav ul li:first-child {
        border-left: 0;
    }

    .buttons-container nav ul li button {
        width: 100%;
        display: block;
        min-width: 10rem;
        height: 2.75rem;
        line-height: 2.75rem;
        text-transform: uppercase;
        list-style: none;
        letter-spacing: 0.2rem;
        font-size: 0.8rem;
        border-bottom: 0;
        list-style: none;
        background-color: var(--transparentBlackColor);
        color: #fff;
        border: solid 1px #ffffff;
        border-radius: 4px;
    }

    .buttons-container nav ul li:hover {
            background-color: rgba(255, 255, 255, 0.6);
        }

        .buttons-container nav ul li button:hover {
            color: #fff;
            cursor: pointer;
            
        }

        .buttons-container nav ul li button:active {
            background-color: rgba(255, 255, 255, 0.175);
        }





.first-storitev{
    display: flex;
    white-space: normal;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;    
    border-radius: 6px;
    padding: 1.5rem;
    box-shadow: -1px 1px 62px -18px rgb(0,0,0,0.8);
    color: var(--mainColor);
}


.first-storitev h2, .first-storitev-reverse h2 {
    font-size: 2rem;
    text-transform: uppercase;
}
.first-storitev-reverse{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;    
    border-radius: 6px;
    padding: 1.5rem;
    box-shadow: -1px 1px 62px -18px rgb(0,0,0,0.8);
    color: var(--mainColor);
}
.first-storitev:hover{
    background-color: var(--mainColor);
    color: var(--textColor);
    transform: scale(1.06);
    transition: 0.2s ease-in-out;
    
}
.first-storitev-reverse:hover{
    background-color: var(--mainColor);
    color: var(--textColor);
    transform: scale(1.06);
    transition: 0.3s ease-in-out;
    
}

.storitev-text {
    box-sizing: border-box;
    width: 45%;
    text-align: start;
    text-overflow: clip;
    overflow-wrap: break-word;
    font-size: 1.1rem;
    line-height: 2rem;
    list-style: none;
    letter-spacing: 0.1rem;
    font-weight: 600;
    border-bottom: 0;
    list-style: none;
}
.storitev-text pre {
    width: 100%;
    white-space: pre-line;
    line-height: 1rem;
}

.storitev-text h2{
    padding-bottom: 1rem;
}

.storitev-image {
    width: 50%;
}

.storitev-image img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgb(0,0,0,0.8);
}



@media screen and (max-width:1100px) {
    .group-wrapper{
        margin: 4rem 1.2rem;
    }
    .first-storitev{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;    
    }
    .first-storitev-reverse{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;    
    }
    .storitev-text {
        width: 100%;
        font-size: 0.9rem;
    }
    .storitev-image {
        width: 100%;
        margin: 1.8rem;
    }
    .image img {
        height: 250px;
    }
    .buttons-container {
        top: 25.5vh;
    }
    .buttons-container nav{
        padding: 0rem;
    }
    .buttons-container nav ul{
        display: flex;
        flex-direction: column;
        border: none;
    }

    .buttons-container nav ul li::after{
        content: '';
		display: block;
		position: relative;
        top: 0;
		left: calc(50% - 1px);
		width: 1px;
		height: 6px;
		background: #ffffff;
    }

    .buttons-container nav ul li:last-child:after{
        display: none;
    }
    


}