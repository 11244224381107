.preloader {
    height: 100vh;
    width: 100vw;
    background: #000;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.preloader .texts-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 240px;
    width: 400px;
    font-size: 20px;
    font-weight: 800;
    overflow: hidden;
    color: #fff;
}
.preloader .texts-container img{
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 800px){
    .preloader .texts-container{
        height: 120px;
        width: 200px;
    }
}