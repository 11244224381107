.slider-show {
  position: relative;
  width: 100%;
  height: 100svh;
  overflow: hidden;
}

.image-slider {
  position: absolute;
  left: 0%;
  width: 100%;
  height: 100svh;
  object-fit: cover;
}

.menuBtnSlider {
  position: absolute;
  bottom: 20vh;
  left: 50%;
  transform: translateX(-50%);
  width: 25%;
  margin-right: auto;
  margin-left: auto;
  z-index: 10; /* Ensure it's above the images */
}

.menuBtnSlider button{
  width: 100%;
  height: 3rem;
  border: 1px solid var(--mainColor);
  border-radius: 4px;
  background-color: var(--transparentBlackColor);
  color:var(--mainColor);
  font-family: var(--fontFamily-forum);
  font-size: 1rem;
  cursor: pointer;
}

.menuBtnSlider button:hover{
  background-color: var(--mainColor);
  color:var(--transparentBlackColor);
}

.slider-controls button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--white);
  border: none;
  cursor: pointer;
  padding: 0.3rem;
  height: 2rem;
  width: 2rem;
}

.slider-controls button:first-of-type {
  left: 10px;
}

.slider-controls button:last-of-type {
  right: 10px;
}

.slider-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: var(--white);
  border-radius: 50%;
  display: inline-block;
}

.dot.active {
  background-color: var(--mainColor);
}

.content {
  position: absolute;
  top: 75vh;
  left: 50%;
  transform: translateX(-50%);
  width: 25%;
  margin-right: auto;
  margin-left: auto;
  padding: 0.5rem;
  opacity: 1;
  line-height: 2rem;
  text-transform: uppercase;
  list-style: none;
  letter-spacing: 0.1rem;
  font-size: 0.7rem;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border-radius: 4px;
  background: var(--transparentBlackColor);
  animation: slide-up 1s ease 0.5s;
  animation-fill-mode: forwards;
  z-index: 20;
}

.content-inside {
  display: flex;   
  flex-direction: column; 
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


@keyframes slide-in {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
}

@media screen and (max-width: 1100px) {
  .content {
    width: 60%;
    font-size: 0.5rem;
    top:  10vh;
    left: 50%;
  }
}

.content > * {
  color: #fff;
}

.current .content {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.5s ease;
}

.content p{
    font-family: var(--fontFamily-dm_sans);
}

hr {
  height: 2px;
  background: white;
  width: 100%;
}