
.reference-wrapper{
    margin: 4rem 6rem;
    color: var(--mainColor);
}

.reference-card{
    margin-top: 3rem;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
}

.r-card{
    width: 100%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.2);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
}

.r-image{
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
}

.r-image img{
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
    object-fit: cover;
}
.r-card:hover img{
    transform: scale(1.3);
    transition: transform 0.3s ease;
    filter: blur(1px);
}

.r-card h4{
    font-size: 1.3rem;
}
.r-card h5{
    text-align: center;
    width: 60%;
    color: white;
    background-color: rgba(0,0,0,0);
    border-radius: 4px;
    font-size: 0.3rem;
}   
.r-card:hover h5{
    position: relative;
    top: -100px;
    left: 0;
    transition: transform 0.3s ease;
    background-color: rgba(0,0,0,0.4);
    font-size: 1.3rem;
}
@media screen and (max-width:850px) {
    .reference-wrapper{
        margin: 4rem 0.5rem;
    }

    .reference-card{
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(2, 1fr);
    }

    .r-card{
        width: 100%;
        margin-bottom: 1.5rem;
    }
    .r-card:hover img{
        transform: none;
        transition: transform 0.3s ease;
        filter: blur(1px);
    }
    .r-card h4{
        padding: 0.9rem 0 0.2rem 0;
        font-size: 1rem;
    }
    .r-card h5{
        display: none;
    }
    .r-card:hover h5{
        display: none;
    }
}