.section_padding{
    padding-bottom: 0.5rem;
}
@media screen and (max-width: 1350px) {
    .section_padding{
        padding: 0rem 2rem;
    }
}

.footer{
    background-color: var(--mainColor);
    padding-top: 2rem;
    width: 100%;
}
.sb_footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    width: 50%;
    margin-bottom: 2rem;
}
@media screen and (max-width: 1350px) {
    .sb_footer{
        flex-direction: column;
        width: 100%;
    }
    .sb_footer{
        padding: 0 0;
    }
    .sb_footer-links{
        margin: 1rem 0;
    }
}
.sb_footer-links{
    width: 50%;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--mainColor);
    text-align: center;
    height: auto;
}

@media screen and (max-width: 850px) {
    .sb_footer-links{
        margin: 0 1rem;
    }
}

.sb_footer-links h2{
    font-size: var(--fontSizePc);
    line-height: 14px;
    margin-bottom: 1rem;
}

.sb_footer-links p, .sb_footer-links a, .sb_footer-links span{
    font-size: 1.2rem;
    line-height: 15px;
    padding: 0.5rem;
    margin: 0;
    text-align: center;
    color: var(--white);
}
.sb_footer-links i{
    font-size: 1.4rem;
    color: var(--white);
    cursor: pointer;
}
.sb_footer-links a, .sb_footer-links span{
    padding: 0;
}

.links{
    margin-top: 2.5rem;
}
@media screen and (max-width: 1350px) {
    .sb_footer-links{
        width: 100%;     
        margin: 1rem 0;
        text-align: left;
    }
    .sb_footer-links h2{
        font-size: var(--fontSizePc);
    }
    .sb_footer-links p, .sb_footer-links span{
        font-size: var(--fontSizePhone);
    }
    .sb_footer-links i{
        font-size: 1.5rem; 
        padding-right: 1rem;
    }
}
a{
    color: var(--textColor);
    flex-direction: row;
}

.sb_footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

hr{
    color: white !important;
    width: 100%;
}

.sb_footer-copyright p{
    font-size: 13px;
    line-height: 15px;
    color: white;
    font-weight: 600;
}
.link-nejc {
    color: black;
}
@media screen and (max-width: 850px) {
    .sb_footer-heading h4{
        font-size: 44px;
        line-height: 50px;
    }
}
    
@media screen and (max-width: 1150px) {
    
    .sb_footer-heading h4{
        font-size: 34px;
        line-height: 42px;
    }
    .sb_footer-links{
        padding: 0 0;
    }
    .sb_footer-links div{
        margin: 1rem 0;
    }
    .sb_footer-btn p{
        font-size: 14px;
        line-height: 20px;
    }

    .sb_footer-below{
        flex-direction: column;
        justify-content: left;
    }
}

@media screen and (max-width: 400px) {
    .sb_footer-heading h4{
        font-size: 38px;
        line-height: 38px;
    }
}